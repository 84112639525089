import LoadableView from "components/common/containers/LoadableView";
import Overlay from "components/common/containers/overlays/Overlay";
import FileInput, { FileInputRef } from "components/common/forms/FileInput";
import useFileHash from "hooks/useFileHash";
import { useApiClient } from "providers/ApiClientProvider";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface AddPastProposalOverlayProps {
  onClose: () => void;
}

const AddPastProposalOverlay: React.FC<AddPastProposalOverlayProps> = ({
  onClose,
}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const fileInputRef = useRef<FileInputRef>(null);
  const hashFile = useFileHash();
  const apiClient = useApiClient();

  return (
    <Overlay
      title="Add Past Proposal"
      className="w-[400px]"
      maxWidth={600}
      onClose={onClose}
    >
      <LoadableView isLoading={isFileUploading}>
        <FileInput
          accept="application/pdf"
          ref={fileInputRef}
          onFileChanged={async (file) => {
            if (!file) return;

            fileInputRef.current?.clear();

            try {
              setIsFileUploading(true);

              // Remove extnesion
              const name = file.name.replace(/\.[^/.]+$/, "");
              const hash = await hashFile(file);

              // Create the record
              const createResponse = await apiClient.rfp.rfpProposalPastCreate({
                hash,
                name,
              });

              // Upload the file
              await fetch(createResponse.data.upload_url!, {
                method: "PUT",
                body: file,
              });

              // Confirm the upload
              await apiClient.rfp.rfpProposalPastConfirmCreate(
                createResponse.data.id!
              );

              onClose();
            } catch (e) {
              toast.error(
                `Failed to upload file: ${message_from_exception(e)}`
              );
            } finally {
              setIsFileUploading(false);
            }
          }}
        />
      </LoadableView>
    </Overlay>
  );
};

export default AddPastProposalOverlay;
