import { useState } from "react";
import Input, { InputProps } from "./Input";

interface MonthInputProps extends Omit<InputProps, "value" | "onChange"> {
  month: number | null;
  year: number | null;
  onChange: (value: { month: number; year: number } | null) => void;
}

const MonthInput: React.FC<MonthInputProps> = ({
  month,
  year,
  onChange,
  onKeyDown,
  onKeyUp,
  ...props
}) => {
  const [textValue, setTextValue] = useState<string>(valueToText(month, year));
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      setIsDeleting(true);
    }
    onKeyDown?.(e);
  };
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      setIsDeleting(false);
    }
    onKeyUp?.(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (!isDeleting) {
      // Only normalize if the user is not deleting text (the backspace key was not pressed)
      newValue = normalizeTextValue(newValue);
    }
    if (newValue === textValue) {
      return;
    }
    setTextValue(newValue);

    if (newValue.includes("/")) {
      const [month, year] = newValue.split("/");
      onChange({
        month: parseInt(month),
        year: parseInt(year),
      });
    } else {
      onChange(null);
    }
  };

  return (
    <Input
      placeholder="mm/yy"
      {...props}
      value={textValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    />
  );
};

const valueToText = (month: number | null, year: number | null) => {
  if (month && year) {
    return `${month}/${year}`;
  }
  if (month) {
    return `${month}`;
  }
  return "";
};

const normalizeTextValue = (textValue: string) => {
  // Disallow non-numeric characters and slashes
  textValue = textValue.replace(/[^0-9/]/g, "");

  // Check for various formats representing the progression of filling out
  // the full input (adding a slash automatically when appropriate)

  // First letter is a slash -> reset to empty string
  if (textValue.startsWith("/")) {
    return "";
  }

  // First letter is a 1 -> it can be either 1 or a two-digit month -> don't add a slash
  if (textValue === "1") {
    return textValue;
  }

  // First letter is a non-1 digit -> it must be a single digit month -> add a slash
  if (textValue.length === 1 && textValue !== "/") {
    return `${textValue}/`;
  }

  // We have two digits -> add a slash
  if (textValue.length === 2 && !textValue.includes("/")) {
    return `${textValue}/`;
  }

  // We have a month followed by a slash -> don't do anything
  if (textValue.match(/^\d{1,2}\/$/)) {
    return textValue;
  }

  // There are multiple slashes -> remove all but the first one
  if ((textValue.match(/\//g) ?? []).length > 1) {
    let normalized = "";
    let found = false;
    for (const char of textValue) {
      if (char === "/" && !found) {
        found = true;
        normalized += char;
      } else if (char !== "/") {
        normalized += char;
      }
    }
    return normalized;
  }

  return textValue;
};

export default MonthInput;
