import { ComponentPropsWithoutRef, FC } from "react";
import Columns from "./common/containers/Columns";
import { cn } from "lib/utils";
import Button from "./common/Button";
import { copyToClipboard } from "lib/clipboard";

interface CopyableTextProps extends ComponentPropsWithoutRef<"div"> {
  text: string;
}

const CopyableText: FC<CopyableTextProps> = ({ text, className, ...props }) => {
  return (
    <Columns {...props} className={cn("items-center", className)}>
      {text}
      <Button
        icon="copy"
        className="-my-md"
        onClick={() => copyToClipboard(text)}
      />
    </Columns>
  );
};

export default CopyableText;
