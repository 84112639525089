import { FC, useRef } from "react";
import { ToolbarButton } from "./Toolbar";
import Icon from "components/common/Icon";
import useImageUpload from "api/useImageUpload";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface ImageToolbarButtonProps {}

const ImageToolbarButton: FC<ImageToolbarButtonProps> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { insertImage } = useImageUpload();

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        await insertImage(file);
        e.target.value = "";
      } catch (e) {
        toast.error(message_from_exception(e));
      }
    }
  };

  return (
    <ToolbarButton tooltip="Insert Image" onClick={handleClick}>
      <Icon name="image" />
      <input
        ref={inputRef}
        onChange={handleFileChange}
        type="file"
        accept="image/png,image/jpg"
        hidden
      />
    </ToolbarButton>
  );
};

export default ImageToolbarButton;
