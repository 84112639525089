import { RFPDetail } from "api/Api";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import Icon from "components/common/Icon";
import Spinner from "components/common/Spinner";
import BasicForm, {
  BasicFormModel,
  BasicFormRef,
} from "components/common/forms/BasicForm";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useRef, useState } from "react";

interface ProposalAdminPopoverProps {
  className?: string;
}

interface RFPDetailsFormModel extends BasicFormModel {
  project_name: string;
  issuing_org: string;
  instructions: string;
  topic: string;
}

const ProposalAdminPopover: React.FC<ProposalAdminPopoverProps> = ({
  className,
}) => {
  const apiClient = useApiClient();
  const { isStaff, isWriter } = useAuthenticatedUser();
  const { details, setDetails } = useProposalData();
  const [rfpDetails, setRfpDetails] = useState<RFPDetail | null>(null);
  const formRef = useRef<BasicFormRef>(null);
  useEffect(() => {
    if (!isStaff && !isWriter) return;
    const fetchData = async () => {
      const response = await apiClient.rfp.rfpRfpRead(details.rfpId);
      setRfpDetails(response.data);
    };
    fetchData();
  }, [apiClient.rfp, isStaff, isWriter, details.rfpId]);
  const handleSubmit = async (model: BasicFormModel) => {
    setRfpDetails((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        project_name: model.project_name,
        issuing_org: model.issuing_org,
      };
    });
    if (model.topic !== details.topic) {
      setDetails((prev) => ({ ...prev, topic: model.topic }));
      // @ts-ignore
      await apiClient.rfp.rfpProposalPartialUpdate(details.id, {
        topic: model.topic,
      });
    }
    await apiClient.rfp.rfpRfpPartialUpdate(details.rfpId, model as any);
    setTimeout(() => {
      if (formRef.current) formRef.current.reset();
    }, 0);
  };
  if (!isStaff && !isWriter) return null;
  const rfpDetailsEmpty =
    rfpDetails && (!rfpDetails.project_name || !rfpDetails.issuing_org);
  return (
    <Popover>
      <PopoverTrigger>
        <Icon
          name="gear"
          className={cn(
            "admin text-primary",
            rfpDetailsEmpty && "text-destructive",
            className
          )}
          variant="solid"
        />
      </PopoverTrigger>
      <PopoverContent className="admin min-w-[400px]">
        {!rfpDetails && <Spinner />}
        {rfpDetails && (
          <BasicForm
            formRef={formRef}
            key={rfpDetails.project_name ?? "" + rfpDetails.issuing_org ?? ""}
            initialModel={
              {
                project_name: rfpDetails.project_name || "",
                issuing_org: rfpDetails.issuing_org || "",
                topic: details.topic || "",
              } as RFPDetailsFormModel
            }
            inputNames={{
              project_name: "Project Name",
              issuing_org: "Issuing Org",
              topic: "Topic",
            }}
            submitText="Save"
            onSubmit={handleSubmit}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

export default ProposalAdminPopover;
