import { cn, mergeRefs } from "lib/utils";
import React, { FC, useEffect } from "react";
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";

/**
 * A container that is automatically collapsed to a smaller version
 * when the content is too large to fit in the parent container.
 *
 * Note that this is currently designed to work with only horizontal content.
 */
export const CollapsableContainer = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(({ children, className, ...rest }, ref) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const collapsableRef = React.useRef<HTMLDivElement>(null);
  const mergedRef = mergeRefs(containerRef, ref);

  const { collapsable, collapsed } = getComponents(children);

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [containerWidth, setContainerWidth] = React.useState(0);

  // TODO: Determine of collapsable is too large to fit in the parent container
  // If it is -> render the collapsed content
  // If it is not -> render the collapsable content

  useEffect(() => {
    if (!containerRef.current || !collapsableRef.current) return;

    const container = containerRef.current;
    const collapsable = collapsableRef.current;

    console.log("container", container, collapsable);

    const observer = new ResizeObserver(() => {
      console.log(container.clientWidth, collapsable.clientWidth);
      if (collapsable.clientWidth > container.clientWidth + 10) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }

      setContainerWidth(container.clientWidth);
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={mergedRef}
      className={cn("overflow-hidden relative", className)}
      {...rest}
    >
      <div className={cn("w-[9999999px] flex", isCollapsed && "opacity-0")}>
        <div
          ref={collapsableRef}
          className="min-w-fit grow"
          style={{ maxWidth: containerWidth }}
        >
          {collapsable}
        </div>
      </div>
      {isCollapsed && <div className="absolute inset-0">{collapsed}</div>}
    </div>
  );
});

const getComponents = (children: ReactNode) => {
  let collapsable: ReactNode = null;
  let collapsed: ReactNode = null;

  React.Children.forEach(children, (child) => {
    if (!child) return;
    if (!collapsable && (child as any).type === CollapsableContent) {
      if (collapsable) {
        throw new Error(
          "CollapsableContainer can only have one CollapsableContent child"
        );
      }
      collapsable = child;
    } else if (!collapsed && (child as any).type === CollapsedContent) {
      if (collapsed) {
        throw new Error(
          "CollapsableContainer can only have one CollapsedContent child"
        );
      }
      collapsed = child;
    }
  });

  if (!collapsable) {
    throw new Error(
      "CollapsableContainer must have a CollapsableContent child"
    );
  }

  return { collapsable, collapsed };
};

// export const CollapsableContent = forwardRef<
//   HTMLDivElement,
//   ComponentPropsWithoutRef<"div">
// >((props, ref) => {
//   return <div ref={ref} {...props} />;
// });

// export const CollapsedContent = forwardRef<
//   HTMLDivElement,
//   ComponentPropsWithoutRef<"div">
// >((props, ref) => {
//   return <div ref={ref} {...props} />;
// });

export const CollapsableContent: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <>{children}</>;
};

export const CollapsedContent: FC<{ children: ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
