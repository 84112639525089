import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import Rows from "components/common/containers/Rows";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import RFPPDFView from "components/rfps/RFPPDFView";
import { format_date } from "lib/utils";
import { rfpFromApiDetails } from "types/RFP";
import Button from "components/common/Button";
import { copyToClipboard } from "lib/clipboard";
import CopyableText from "components/CopyableText";
import { RFPCategory } from "api/Api";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Icon from "components/common/Icon";

interface RFPDetailsOverlayProps {
  rfpId: string;
  rfpName: string;
  onDismiss?: () => void;
}

interface LocalRFPCategory {
  id: string;
  name: string;
  displayName: string;
}

const RFPDetailsOverlay: FC<RFPDetailsOverlayProps> = ({
  rfpId,
  rfpName,
  onDismiss,
}) => {
  const apiClient = useApiClient();
  const [rfp, setRfp, { error }] = useFetchedData(async () => {
    const response = await apiClient.rfp.rfpRfpRead(rfpId);
    return rfpFromApiDetails(response.data);
  });
  const [allCategories, , paginatedData] = usePaginatedData<
    LocalRFPCategory,
    RFPCategory
  >({
    endpoint: apiClient.rfp.rfpCategoryList,
    map: (remote) => ({
      id: remote.name,
      name: remote.name,
      displayName: remote.display_name,
    }),
  });

  const handleRegenerateProfile = async () => {
    try {
      await apiClient.rfp.rfpRfpRegenerateProfileCreate(rfpId!);
      toast.success("Profile requeued successfully");
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  const handleToggleCategory = async (category: LocalRFPCategory) => {
    if (!rfp) return;
    setRfp((prev) => {
      if (!prev) return prev;

      const isSelected = prev.categories.find(
        (value) => value.name === category.name
      );
      let newDetails = prev;
      if (isSelected) {
        newDetails = {
          ...prev,
          categories: prev.categories.filter(
            (value) => value.name !== category.name
          ),
        };
      } else {
        newDetails = {
          ...prev,
          categories: [
            ...prev.categories,
            { name: category.name, displayName: category.displayName },
          ],
        };
      }
      apiClient.rfp
        .rfpRfpPartialUpdate(rfpId, {
          // @ts-ignore
          categories: newDetails.categories,
        })
        .catch((e) => toast.error(message_from_exception(e)));
      return newDetails;
    });
  };

  return (
    <Overlay
      title={rfpName ?? undefined}
      open={!!rfpId}
      onClose={onDismiss}
      variant="bordered"
    >
      <Columns>
        {/* Details Form */}
        <AsyncLoadedDiv
          value={rfp}
          error={error ? message_from_exception(error) : undefined}
          className="grow basis-0 flex flex-col"
          whileLoaded={(rfp) => {
            return (
              <Rows className="gap-md grow">
                <LabeledFormInput label="Id">
                  <CopyableText text={rfp.id} />
                </LabeledFormInput>
                <LabeledFormInput label="Created">
                  {format_date(rfp.created)}
                </LabeledFormInput>
                <LabeledFormInput label="Issuing Org">
                  <CopyableText text={rfp.issuingOrg ?? "unknown"} />
                </LabeledFormInput>
                <LabeledFormInput
                  label="Labels"
                  className="grow flex flex-col overflow-hidden"
                >
                  <PaginatedTableView
                    searchable={true}
                    className="grow"
                    results={allCategories}
                    paginatedData={paginatedData}
                    columns={[{ size: "min" }, { name: "Name" }]}
                    onSelect={(item) => handleToggleCategory(item)}
                    renderRow={(item, Cell, Row) => {
                      const isSelected = rfp.categories.some(
                        (c) => c.name === item.name
                      );
                      return (
                        <Row key={item.id}>
                          <Cell>{isSelected && <Icon name="check" />}</Cell>
                          <Cell>{item.displayName}</Cell>
                        </Row>
                      );
                    }}
                  />
                </LabeledFormInput>
                <Columns className="shrink-0">
                  <Button
                    icon="address-card"
                    text="Regenerate Profile"
                    variant="destructive"
                    onClick={() => handleRegenerateProfile()}
                  />
                </Columns>
              </Rows>
            );
          }}
        />
        {/* RFP Preview */}
        <Rows className="grow basis-0">
          <RFPPDFView rfpId={rfpId} />
        </Rows>
      </Columns>
      {/* <Rows className="gap-md"> */}
      {/* <RFPCategoryEditor
          rfpId={rfpId}
          rfpName={rfpName}
          onSaved={fetchData.refresh}
        /> */}
      {/* <LabeledFormInput label="Comparisons">
          <ComparisonTableView
            differentiating={{ column: "Orgs", id: rfpId }}
            results={comparisons}
            paginatedData={fetchData}
          >
            <Button
              icon="address-card"
              text="Regenerate Profile"
              variant="destructive"
              onClick={() => handleRegenerateProfile()}
            />
            <div className="grow" />
          </ComparisonTableView>
        </LabeledFormInput>
        <Columns className="shrink-0"> */}
      {/* <div className="grow" /> */}
      {/* <Button
            icon="refresh"
            text="Rerun All"
            variant="destructive"
            onClick={() => handleRerun()}
          /> */}
      {/* </Columns>
      </Rows> */}
    </Overlay>
  );
};

// const RFPCategoryEditor: FC<{
//   rfpId: string;
//   rfpName: string;
//   onSaved: () => void;
// }> = ({ rfpId, rfpName, onSaved }) => {
//   const apiClient = useApiClient();
//   const [details, setDetails, { error }] = useFetchedData(async () => {
//     return (await apiClient.rfp.rfpRfpRead(rfpId)).data;
//   });
//   const [changed, setChanged] = useState(false);
//   const [isCategorizing, setIsCategorizing] = useState(false);

//   const handleSave = async () => {
//     if (!details) return;
//     if (!changed) return;
//     try {
//       await apiClient.rfp.rfpRfpUpdate(rfpId, details);
//       toast.success("Category updated successfully");
//       setChanged(false);
//       onSaved();
//     } catch (e) {
//       toast.error(message_from_exception(e));
//     }
//   };

//   return (
//     <AsyncLoadedDiv
//       error={error ? message_from_exception(error) : undefined}
//       value={details}
//       whileLoaded={(details) => {
//         return (
//           <LabeledFormInput label="Category">
//             <RFPCategoriesDropDown
//               className="grow"
//               categories={details.categories}
//               onChange={(categories) => {
//                 setDetails((prev) => {
//                   if (!prev) return prev;
//                   return { ...prev, categories };
//                 });
//                 setChanged(true);
//               }}
//               onSave={handleSave}
//             />
//           </LabeledFormInput>
//         );
//       }}
//     />
//   );
// };

export default RFPDetailsOverlay;
