import { init } from "@sentry/react";
import { RFPCategory } from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import BasicForm, { BasicFormModel } from "components/common/forms/BasicForm";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useState } from "react";

interface Service extends BasicFormModel {
  name: string;
  display_name: string;
}

const ServicesRoute = () => {
  const apiClient = useApiClient();

  const [services, setServices, paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpCategoryList,
    map: (remote) => remote,
  });

  const [editingService, setEditingService] = useState<
    RFPCategory | null | undefined
  >(undefined);

  return (
    <Rows className="grow p-lg gap-md">
      <Columns className="gap-md shrink-0">
        <h1 className="text-2xl font-semibold">Services</h1>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setEditingService(null)}
        />
      </Columns>
      <PaginatedTableView
        results={services}
        searchable={true}
        paginatedData={paginatedData}
        columns={[{ name: "Name" }, { name: "Display Name" }]}
        onSelect={(service) => setEditingService(service)}
        renderRow={(service, Cell, Row) => (
          <Row key={service.name}>
            <Cell>{service.name}</Cell>
            <Cell>{service.display_name}</Cell>
          </Row>
        )}
      />
      {editingService !== undefined && (
        <Overlay onClose={() => setEditingService(undefined)}>
          <EditServiceForm
            service={editingService as any}
            onSave={(service) => {
              setServices((prev) => {
                if (!prev) return prev;
                if (editingService === null) {
                  return [...prev, service];
                } else {
                  return prev.map((s) => {
                    if (s.id === editingService.id) {
                      return { ...editingService, ...service };
                    }
                    return s;
                  });
                }
              });
              setEditingService(undefined);
            }}
          />
        </Overlay>
      )}
    </Rows>
  );
};

interface EditServiceFormProps {
  service: RFPCategory | null;
  onSave: (service: Service) => void;
}

const EditServiceForm: FC<EditServiceFormProps> = ({ service, onSave }) => {
  const apiClient = useApiClient();

  const handleSubmit = async (updated: Service) => {
    if (!updated.display_name) {
      throw new Error("Display Name is required");
    }

    if (!updated.name) {
      throw new Error("Name is required");
    }

    if (!/^[a-z-]+$/.test(updated.name)) {
      throw new Error("Name must be lower case with only dashes");
    }

    if (service) {
      await apiClient.rfp.rfpCategoryPartialUpdate(service.id!, updated);
    } else {
      await apiClient.rfp.rfpCategoryCreate(updated);
    }
    onSave(updated);
  };

  return (
    <BasicForm<Service>
      initialModel={
        service
          ? { name: service.name, display_name: service.display_name }
          : {
              name: "",
              display_name: "",
            }
      }
      inputNames={{
        name: "Name",
        display_name: "Display Name",
      }}
      submitText="Save"
      onSubmit={handleSubmit}
    />
  );
};

export default ServicesRoute;
