import PaginatedTableView from "components/common/containers/PaginatedTableView";
import useFixedPaginatedData from "hooks/useFixedPaginatedData";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface AdminOption {
  name: string;
  description: string;
  route: string;
  requiresStaff?: boolean;
}

const ALL_OPTIONS: AdminOption[] = [
  {
    name: "Prompts",
    description: "Tool and playground to manipulate the prompts we're using.",
    route: "/admin/prompts/",
  },
  {
    name: "RFPs",
    description: "Manage RFPs",
    route: "/admin/rfps/",
  },
  {
    name: "Unpublished Proposals",
    description: "View unpublished proposals.",
    route: "/admin/proposals/",
    requiresStaff: false,
  },
  {
    name: "Search",
    description: "Search an organization content.",
    route: "/admin/search/",
    requiresStaff: false,
  },
  {
    name: "Events",
    description: "View and investigate debugging events/logs.",
    route: "/admin/events",
  },
  {
    name: "Orgs",
    description: "Manage organizations",
    route: "/admin/orgs/",
  },
  {
    name: "Services",
    description: "Manage the service tags for RFP and org matching.",
    route: "/admin/services/",
  },
  {
    name: "Editor",
    description: "Test Editor",
    route: "/admin/editor/",
  },
];

const AdminRoute = () => {
  const navigate = useNavigate();
  const user = useAuthenticatedUser();

  const [availableOptions, setAvailableOptions] = useState<AdminOption[]>([]);

  useEffect(() => {
    setAvailableOptions(
      ALL_OPTIONS.filter(
        (option) => option.requiresStaff === false || user?.isStaff === true
      )
    );
  }, [user.isStaff]);

  const [options, paginatedData] = useFixedPaginatedData<AdminOption>(
    availableOptions,
    []
  );

  return (
    <>
      <PaginatedTableView
        results={options}
        paginatedData={paginatedData}
        columns={[{ name: "Page", size: "min" }, { name: "Description" }]}
        onSelect={(option) => navigate(option.route)}
        renderRow={(option, Cell, Row) => (
          <Row key={option.name}>
            <Cell>{option.name}</Cell>
            <Cell>{option.description}</Cell>
          </Row>
        )}
      />
    </>
  );
};

export default AdminRoute;
