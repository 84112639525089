import { Org, OrgDetails } from "api/Api";
import Button from "components/common/Button";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Overlay from "components/common/containers/overlays/Overlay";
import Toggle from "components/common/forms/Toggle";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import OrgDetailsView from "./OrgDetailsView";
import BasicForm from "components/common/forms/BasicForm";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Pill from "components/common/Pill";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/EditorView/Menus/Tooltip";

const OrgList = () => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const [orgs, setOrgs, paginatedData] = usePaginatedData({
    endpoint: apiClient.orgs.orgsList,
    map: (org) => org,
  });
  const [addingOrg, setAddingOrg] = useState(false);
  const [viewingDetails, setViewingDetails] = useState<Org | null>(null);

  const setOrg = async (org: Org) => {
    try {
      await apiClient.orgs.orgsSetCreate({ public_id: org.public_id! });
      navigate("/");
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  const toggleTrial = async (org: Org) => {
    try {
      await apiClient.orgs.orgsPartialUpdate(org.public_id!, {
        is_trial_approved: !org.is_trial_approved,
      } as Partial<OrgDetails> as any);
      setOrgs((orgs) => {
        if (!orgs) return orgs;
        return orgs.map((o) => {
          if (o.public_id === org.public_id) {
            return { ...o, is_trial_approved: !o.is_trial_approved };
          }
          return o;
        });
      });
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  const toggleSub = async (org: Org) => {
    try {
      await apiClient.orgs.orgsPartialUpdate(org.public_id!, {
        is_subscribed: !org.is_subscribed,
      } as Partial<OrgDetails> as any);
      setOrgs((orgs) => {
        if (!orgs) return orgs;
        return orgs.map((o) => {
          if (o.public_id === org.public_id) {
            return { ...o, is_subscribed: !o.is_subscribed };
          }
          return o;
        });
      });
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  return (
    <Rows className="grow p-lg gap-md">
      <Columns className="gap-md shrink-0">
        <h1 className="text-2xl font-semibold">Organizations</h1>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setAddingOrg(true)}
        />
      </Columns>
      <PaginatedTableView
        results={orgs}
        paginatedData={paginatedData}
        searchable={true}
        columns={[
          { name: "Enter", size: "min" },
          { name: "Name" },
          { name: "Categories", size: "min" },
          { name: "Domains", size: "min" },
          { name: "Trial", size: "min" },
          { name: "Sub", size: "min" },
        ]}
        onSelect={(org) => setViewingDetails(org)}
        renderRow={(org, Cell, Row) => (
          <Row key={org.public_id!}>
            <Cell>
              <Button
                onClick={() => setOrg(org)}
                icon="arrow-left-to-bracket"
                variant="outline-full"
              />
            </Cell>
            <Cell>{org.name}</Cell>
            <Cell>
              <Tooltip>
                <TooltipTrigger className="flex gap-xs cursor-default max-w-[400px]">
                  {(org.categories ?? []).map((org) => {
                    return (
                      <Pill
                        text={org}
                        key={org}
                        className="inline-block min-w-[48px] ml-xs"
                      />
                    );
                  })}
                </TooltipTrigger>
                <TooltipContent>
                  <Rows className="gap-xs">
                    {(org.categories ?? []).map((cat) => (
                      <p key={cat} className="inline-block min-w-[48px]">
                        {cat}
                      </p>
                    ))}
                  </Rows>
                </TooltipContent>
              </Tooltip>
            </Cell>
            <Cell>
              {(org.email_domains ?? []).map((d) => d.domain).join(", ")}
            </Cell>
            <Cell>
              <Toggle
                onToggle={() => toggleTrial(org)}
                on={org.is_trial_approved ?? false}
                size="large"
              />
            </Cell>
            <Cell>
              <Toggle
                onToggle={() => toggleSub(org)}
                on={org.is_subscribed ?? false}
                size="large"
              />
            </Cell>
          </Row>
        )}
      />
      <Overlay
        open={addingOrg}
        onClose={() => setAddingOrg(false)}
        className="max-w-[360px]"
      >
        <BasicForm
          initialModel={{ name: "", domain: "" }}
          inputNames={{
            name: "Name",
            domain: "Domain",
          }}
          submitText="Create"
          onSubmit={async (model) => {
            try {
              await apiClient.orgs.orgsCreateCreate({
                name: model.name,
                domain: model.domain,
              });
              setAddingOrg(false);
              paginatedData.refresh();
            } catch (e) {
              toast.error(message_from_exception(e));
            }
          }}
        />
      </Overlay>
      <Overlay
        open={!!viewingDetails}
        onClose={() => setViewingDetails(null)}
        title={viewingDetails?.name ?? ""}
      >
        {viewingDetails && (
          <OrgDetailsView
            org={viewingDetails}
            onDeleted={() => {
              setOrgs(
                (prev) =>
                  prev?.filter(
                    (o) => o.public_id !== viewingDetails.public_id
                  ) ?? null
              );
            }}
          />
        )}
      </Overlay>
    </Rows>
  );
};

export default OrgList;
